<template>
  <div>Загрузка...</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: mapGetters("user", ["isLoggedIn"]),
  async mounted() {
    try {
      let formData = { ...this.$route.query };
      const refCode = localStorage.getItem("refCode") || null;
      if (refCode) formData.refCode = refCode;
      if (!this.isLoggedIn) {
        const fp = await this.$store.dispatch("user/getFingerprint");
        formData.fp = fp;
      }

      const { data } = await this.$http.post("auth/social", formData);
      const { access_token } = data.response;
      this.$store.dispatch("user/login", { access_token });
    } catch (error) {
      this.$toast.info(error?.message);
    }

    this.$router.push("/");
  },
};
</script>
